.fade.left.modal .modal-dialog {
  position: fixed;
  padding: 0px;
  width: 380px;
  height: 100vh;
  left: 0px;
  margin: auto;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.fade.left.modal .modal-content {
  height: 100%;
  overflow-y: auto;
}

.fade.left.modal .modal-body {
  padding: 0px;
}

.fade.left.modal a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #000;
  font-weight: bold;
  display: block;
  transition: 0.3s;
  font-family: "Lucida";
  @media screen and (max-height: 450px) {
    .multilevelSideNav a {
      font-size: 18px;
    }
  }
}

.fade.left.modal a:hover {
  color: rgba(0, 0, 0, 0.6);
}

.fade.left.modal {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.fade.left.modal.show {
  left: 0;
}

.fade.left.modal .modal-content {
  border-radius: 0;
  border: none;
}

.left.slide-in-left {
  animation: slide-in-left 0.5s ease-out;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-out-left {
  animation: slide-out-left 5s ease-out;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.muiListItemText-primary {
  font-family: Lucida;
}

.close_icon_effect_filter {
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.close_icon_effect {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: transparent !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
.MuiTextField-root {
  width: 100%;
  background-color: #fdfbfa !important;
}

.MuiFormControlLabel-label {
  font-family: "Lucida" !important;
}

.custom-dialog {
  top: -130px;
}

@media (max-width: 1200px) {
  .custom-dialog {
    top: -118px;
  }
}

@media (max-width: 1000px) {
  .custom-dialog {
    top: -100px;
  }
}

@media (max-width: 800px) {
  .custom-dialog {
    top: -90px;
  }
}

@media (max-width: 600px) {
  .custom-dialog {
    top: -80px;
  }
}

@media (max-width: 400px) {
  .custom-dialog {
    top: -65px;
  }
}

@media (max-width: 200px) {
  .custom-dialog {
    top: 0px;
  }
}
