$theme-colors: (
  primary: #604331,
  secondary: #6c757d,
  success: #198754,
  info: #0dcaf0,
  warning: #ffc107,
  danger: #dc3545,
  light: #f8f9fa,
  dark: #212529,
);
@import "bootstrap/scss/bootstrap.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";
@import "very-custom";
// @import "bootstrap";

.hide-chat {
  display: none !important;
}

.custom-select {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #000;
  height: 40px;
  padding: 0 15px;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 5px;
  flex: 0 0 auto; /* Ensure select does not stretch */
}

.phone-wrapper {
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #a3968f; /* Color of the dot */
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: 8px;
}

.order-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background-color: rgba(240, 244, 224, 0.8);
  color: #604331;
  border: 0px solid #604331;
  padding: 8px;
  min-width: 180px;
  font-weight: bold;
}

.order-button:hover {
  background-color: rgba(240, 244, 224, 1);
  color: #604331;
}

.dynamic-button {
  position: absolute;
  background-color: rgba(95, 67, 50, 0.8);
  color: rgb(240, 244, 224);
  border: 0px solid black;
  padding: 8px;
  min-width: 180px;
  font-weight: bold;
}

.dynamic-button {
  position: absolute;
  background-color: rgba(95, 67, 50, 0.8);
  color: rgb(240, 244, 224);
  border: 0px solid black;
  padding: 8px;
  min-width: 180px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .dynamic-button {
    top: 10px;
    right: 20px;
    bottom: auto;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dynamic-button {
    bottom: 10px;
    left: 20px;
    top: auto;
    right: auto;
  }
}

.responsive-heading {
  position: absolute;
  top: 20px;
  left: 20px;
  white-space: nowrap;
  line-height: 1.2;
  color: rgb(240, 244, 224);
}

/* Extra Extra small: <519px */
@media (max-width: 376px) {
  .responsive-heading {
    font-size: 15px; /* Adjust font size for small screens */
  }
}

/* Extra small (xs): ≥377px */
@media (min-width: 377px) {
  .responsive-heading {
    font-size: 18px; /* Adjust font size for small screens */
  }
}

/* Small (sm): ≥576px */
@media (min-width: 520px) {
  .responsive-heading {
    font-size: 24px; /* Adjust font size for small screens */
  }
}

/* Medium (md): ≥768px */
@media (min-width: 768px) {
  .responsive-heading {
    font-size: 18px; /* Adjust font size for medium screens */
    text-align: left; /* Optional: Change alignment for medium screens */
  }
}

/* Large (lg): ≥992px */
@media (min-width: 992px) {
  .responsive-heading {
    font-size: 24px; /* Adjust font size for large screens */
  }
}

/* Extra Large (xl): ≥1200px */
@media (min-width: 1200px) {
  .responsive-heading {
    font-size: 28px; /* Adjust font size for extra large screens */
  }
}

/* Main container styling */
.info-container {
  position: absolute;
  top: 65px;
  left: 20px;
}

/* Paragraphs with reduced line-height */
.info-container p {
  line-height: 0.2;
}

/* "Learn more" styling */
.learn-more {
  margin-top: 17px;
  text-decoration: underline;
  cursor: pointer;
}

/* Font size responsiveness */
@media (max-width: 991px) {
  .info-container p {
    font-size: 14px; /* Adjust for medium screens */
  }

  .info-container {
    top: 55px;
  }

  .learn-more {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .info-container p {
    font-size: 16px; /* Adjust for smaller screens */
  }

  .info-container {
    top: 60px;
  }

  .learn-more {
    font-size: 16px;
  }
}

@media (max-width: 520px) {
  .info-container p {
    font-size: 12px; /* Adjust for even smaller screens */
  }

  .info-container {
    top: 50px;
  }

  .learn-more {
    font-size: 12px;
  }
}

@media (max-width: 376px) {
  .info-container p {
    font-size: 10px; /* Make font size even smaller for very small screens */
  }

  .learn-more {
    font-size: 10px;
  }
}

/* Target individual thumbnail images */
.yarl__thumbnails_container {
  background-color: #f4f3f1 !important; /* Background color for each thumbnail */
}

.yarl__thumbnail {
  background-color: #f4f3f1 !important; /* Background color for each thumbnail */
}

.yarl__thumbnails_vignette {
  background: linear-gradient(
    to right,
    #f4f3f1 0px,
    transparent 12% calc(100% - 12%),
    #f4f3f1 100%
  ) !important;
}

.yarl__thumbnails_thumbnail {
  background: #f4f3f1 !important;
  border: 1px solid #5f4231 !important;
}

.yarl__button {
  color: #5f4231 !important;
}

.yarl__button:disabled {
  color: rgba(95, 66, 49, 0.4) !important;
}
